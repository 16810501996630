.cookie__banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
}

.cookie__banner--trigger {
  cursor: pointer;
}

.cookie__banner--content,
.cookie__banner--config {
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  transform: translateY(-30%);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
  font-size: 1em;
  line-height: 1.5;
  overflow-y: auto;
  padding: 4em 5em 3em;
  width: 960px;
  margin: 0 auto;
  max-height: 80vh;
  overflow-x: auto;
  z-index: 9999;
}

.cookie__banner--content.hide--content {
  display: none;
}

.cookie__text {
  margin-bottom: 3em;
}

.cookie__banner--title {
  margin-top: 0;
}

.cookie__text p,
.cookie__banner--note {
  margin-bottom: 2em;
}

.cookie__banner--item-description {
  max-width: 80%;
}

.cookie__text .cookie__text p:last-child {
  margin-bottom: 0;
}

.cookie__row--navigation {
  margin-bottom: 1em;
}

.cookie__row--navigation {
  display: flex;
}

.cookie__row--navigation button {
  cursor: pointer;
  margin: 0 2.5em 0 0;
}

.cookie__row--navigation button:hover,
.cookie__row--navigation button:focus,
.cookie__row--navigation button:active,
.cookie__row--navigation button:active:focus {
  outline: none;
  box-shadow: none;
}

.cookie__row--button-required {
  display: none;
}

.cookie__banner--list {
  list-style: none;
  margin: 2em 0;
  padding: 0;
  text-align: left;
}

/* bootstrap styles */
.cookie__banner .custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding: 0;
}

.cookie__banner .custom-control-input {
  position: absolute;
  top: 45px;
  right: 0;
  display: block;
  pointer-events: all;
}

.cookie__banner input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

.cookie__banner .custom-control-label {
  display: inline-block;
  position: relative;
  margin: 0;
  padding-top: 1em;
  vertical-align: top;
  line-height: 1.2;
  font-size: x-large;
  width: 100%;
  pointer-events: none;
}

.cookie__banner .custom-control-label > span {
  max-width: 80%;
  display: inline-block;
}

.cookie__banner--control.hasCookielist {
  padding-left: 20px;
}

.cookie__banner--control-cookies {
  position: absolute;
  bottom: 4px;
  left: -5px;
  width: 20px;
  height: 20px;
}

.cookie__banner--control-cookies-icon::before {
  content: "+";
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cookie__banner--control-cookies-icon.cookie--list-open::before {
  content: "-";
}

.cookie__banner--control-counter {
  background-color: lightgrey;
  border-radius: 5em;
  width: 20px;
  height: 20px;
  position: relative;
  top: -10px;
  left: 5px;
}

.cookie__banner--control-counter span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 12px;
}

.cookie__banner--cookielist {
  padding: 20px 0;
}

.cookie__banner--cookieInfo {
  background-color: whitesmoke;
  padding: 20px 30px;
  margin: 10px 0;
}

/* MOBILE */
@media (max-width: 1230px) {
  .cookie__banner--content,
  .cookie__banner--config {
    width: 90%;
  }
}

@media (max-width: 1014px) {
  .cookie__banner--content,
  .cookie__banner--config {
    padding: 2em;
  }

  .cookie__row--navigation {
    flex-wrap: wrap;
    width: 100%;
  }

  .cookie__row--navigation button {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .cookie__row--button-advanced {
    order: 2;
  }

  .cookie__row--button-default {
    order: 1;
  }
}

@media screen and (max-width: 767px) {
  .cookie__banner--content,
  .cookie__banner--config {
    padding: 2em 1em;
    width: 100%;
    max-height: 80vh;
    top: auto;
    transform: none;
    bottom: 0;
  }
}
